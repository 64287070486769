import '../css/styles.scss'
import Site from '../lib/Site.js'

import '@fontsource/roboto/latin-300.css'
import '@fontsource/roboto/latin-300-italic.css'
import '@fontsource/roboto/latin-400.css'
import '@fontsource/roboto/latin-400-italic.css'
import '@fontsource/roboto/latin-500.css'
import '@fontsource/roboto/latin-500-italic.css'
import '@fontsource/roboto/latin-700.css'
import '@fontsource/roboto/latin-700-italic.css'
import '@fontsource/roboto/latin-900.css'
import '@fontsource/roboto/latin-900-italic.css'

import '@fontsource/oswald/latin-400.css'
import '@fontsource/oswald/latin-500.css'

if (document.body.id !== 'form-builder-form') {
	window._site = new Site()
}
