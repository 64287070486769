export default class ServingSizeCalculator {
	constructor () {
		// Ingredients table
		this.ingredientsTable = document.querySelector('.ingredients')
		if (!this.ingredientsTable) return

		// How many persons does this recipe serve?
		this.fromServings = parseInt(this.ingredientsTable.getAttribute('data-servings'))

		// Target number of servings
		this.toServings = this.fromServings

		// Serving size element
		this.servingSizeAmount = document.getElementById('serving-size--amount')

		// Alert
		this.servingSizeAlert = document.getElementById('serving-size-calculator--alert')

		// Array of amounts
		this.amounts = []

		// Loop all rows and gather amounts
		for (const row of this.ingredientsTable.querySelectorAll('.row')) {
			const amountCol = row.querySelector('.serving-size-calculator--ingredient-amount')
			const descCol = row.querySelector('.row-incridient')

			if (!amountCol || !amountCol.textContent.length || !descCol.textContent.length) continue

			amountCol.setAttribute('data-original-amount', amountCol.textContent.trim())
			this.amounts.push(amountCol)
		}

		// Decrease button
		this.decreaseBtn = document.getElementById('serving-size--decrease')
		if (this.decreaseBtn) {
			this.decreaseBtn.addEventListener('click', () => {
				if (this.toServings === 1) return

				this.toServings--
				this.updateState()
			})
		}

		// Increase button
		this.increaseBtn = document.getElementById('serving-size--increase')
		if (this.increaseBtn) {
			this.increaseBtn.addEventListener('click', () => {
				this.toServings++
				this.updateState()
			})
		}
	}

	updateState () {
		this.servingSizeAmount.textContent = this.toServings
		this.calculateIngredients(this.toServings)

		// Enable/disable decrease button
		if(this.toServings === 1) {
			this.decreaseBtn.setAttribute('disabled', true)
			this.decreaseBtn.classList.add('serving-size-button--disabled')
		} else {
			this.decreaseBtn.removeAttribute('disabled')
			this.decreaseBtn.classList.remove('serving-size-button--disabled')
		}

		// Show/hide alert
		if (this.servingSizeAlert) {
			if(this.toServings === this.fromServings) {
				this.servingSizeAlert.style.display = 'none'
			} else {
				this.servingSizeAlert.style.display = 'block'
			}
		}
	}

	parseAmount (amount = '') {
		if (!this.fromServings || !this.toServings) return amount

		amount = amount.trim()
		if (!amount) return null

		// If amount contains dash, split and parse values separately
		if (amount.match(/[\-–]/gi)) {
			return amount
				.split(/[\-–]/gi)
				.map(subAmount => this.parseAmount(subAmount))
				.join(' – ')
		} else if (amount.includes('/')) {
			const subAmounts = amount.split('/')

			if (subAmounts.length == 2) {
				amount = (parseInt(subAmounts[0]) / parseInt(subAmounts[1])).toString()
			}
		}

		let out = parseFloat(amount) || 0

		if (amount.includes('½')) out += 0.5
		if (amount.includes('⅓')) out += 0.33
		if (amount.includes('⅔')) out += 0.66
		if (amount.includes('¼')) out += 0.25
		if (amount.includes('¾')) out += 0.75

		// Calculate amount
		out = ((out / this.fromServings) * this.toServings).toFixed(1)

		// If amount is a whole number, return as integer
		if (out % 1 === 0) return parseInt(out)

		// Return as formatted float
		return out.toString().replace('.', ',')
	}

	calculateIngredients () {
		if (!this.amounts.length) return

		for (const amount of this.amounts) {
			if (this.toServings === this.fromServings) {
				amount.textContent = amount.getAttribute('data-original-amount')
				continue
			}

			// Build an object of replacements in format: { value: replacement }
			const replacements = [
				...amount
					.getAttribute('data-original-amount')
					.matchAll(/^(?:noin |\(?n\. )?([0-9½⅓⅔¼¾ ,\.\-–/]+)?([a-z ]*)?([(])?([0-9½⅓⅔¼¾ ,\.\-–/]+)?(.*)?/gi),
			][0].slice(1).reduce((acc, item) => {
				if (!item) return acc

				const matches = item.trim().match(/[0-9½⅓⅔¼¾ ,\.\-–/]+/gi)
				if (!matches) return acc

				acc[item] = this.parseAmount(item)

				return acc
			}, {})

			// If replacements array contains only zeros, hide column
			if (!Object.values(replacements).filter(item => parseFloat(item.toString().replace(',', '.')) > 0).length) {
				amount.classList.add('serving-size-calculator--ingredient-amount--hidden')
				continue
			} else {
				amount.classList.remove('serving-size-calculator--ingredient-amount--hidden')
			}

			// Apply replacements
			amount.textContent = (() => {
				let out = amount.getAttribute('data-original-amount')

				for (const key in replacements) {
					out = out.replace(key, `${replacements[key]} `)
				}

				return out
			})()
		}
	}
}
