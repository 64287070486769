'use strict'

/**
 * Suggest Search class
 *
 * This class provides AJAX based search suggestions (autocomplete search) with some help from the SearchEngine module.
 * Enabling search suggestions:
 *
 * - Make sure that SearchController responds to AJAX requests with SearchEngine's JSON output.
 * - Wrap the query string input of a search form with div#js-search-suggestions-container. This container needs to
 *   have data-all-label attribute with a text value to use for the "more results" button (preferably translatable).
 * - There's a default stylesheet in /css/components/_suggest-search.scss. Refer to this file from styles.scss and
 *   customize the styles to fit current site.
 * - Instantiate SuggestSearch in Site.js and call the init() method.
 */
export default class SuggestSearch {

	/**
	 * Init method
	 */
	init() {

		// find required DOM nodes
		this.searchForm = document.getElementById('se-form-desktop')
		this.searchInput = document.getElementById('se-form-desktop-input')
		this.suggestionsContainer = document.getElementById('js-search-suggestions-container')

		// bail out early if any of the missing DOM nodes are missing
		if (!this.searchForm || !this.searchInput || !this.suggestionsContainer) return

		// local cache variables
		this.searchURL = this.searchForm.getAttribute('action')
		this.autocompleteCache = {}
		this.autocompleteTimeout = null

		// add aria-attributes
		this.searchInput.setAttribute('aria-autocomplete', 'list')
		this.searchInput.setAttribute('aria-controls', 'js-search-suggestions')
		this.searchInput.setAttribute('aria-expanded', 'false')
		this.searchInput.setAttribute('aria-haspopup', 'listbox')

		// add role attribute
		this.searchInput.setAttribute('role', 'combobox')

		// disable browser's autocomplete
		this.searchInput.setAttribute('autocomplete', 'off')

		// add event listeners
		this.searchInput.addEventListener('focus', () => {
			this.searchInput.classList.add('focus')
		})
		this.searchInput.addEventListener('blur', () => {
			this.maybeRemoveSuggestions()
		})
		this.searchInput.addEventListener('keyup', (e) => {
			if (this.handleArrowKeys(e)) return
			this.getSuggestions()
		})
		this.searchInput.addEventListener('focus', () => {
			this.getSuggestions()
		})

		// add status element
		this.searchStatus = document.getElementById('se-form-desktop-status')
		if (!this.searchStatus) {
			this.searchStatus = document.createElement('div')
			this.searchStatus.setAttribute('id', 'se-form-desktop-status')
			this.searchStatus.classList.add('sr-only')
			this.searchStatus.setAttribute('role', 'status')
			this.searchStatus.setAttribute('aria-live', 'polite')
			this.searchStatus.setAttribute('aria-atomic', 'true')
			this.searchForm.appendChild(this.searchStatus)
		}
	}

	/**
	 * Handle up and down arrow keys
	 *
	 * @param {Event} e
	 * @returns {boolean} true if arrow key was handled, false otherwise
	 */
	handleArrowKeys(e) {
		if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
			const selected = this.suggestionsContainer.querySelector('[aria-selected="true"]')
			if (selected) {
				const next = e.key === 'ArrowDown' ? selected.nextElementSibling : selected.previousElementSibling
				if (next) {
					e.preventDefault()
					next.setAttribute('aria-selected', 'true')
					next.querySelector('a').focus()
				}
			} else {
				const first = this.suggestionsContainer.querySelector('a')
				if (first) {
					e.preventDefault()
					first.parentElement.setAttribute('aria-selected', 'true')
					first.focus()
				}
			}
			return true
		}
		return false
	}

	/**
	 * Remove suggestions with delay (unless focused)
	 */
	maybeRemoveSuggestions() {
		window.setTimeout(() => {
			if (!this.suggestionsContainer.querySelector(':focus')) {
				this.removeSuggestions()
			}
		}, 500)
	}

	/**
	 * Hide/remove search suggestions
	 */
	removeSuggestions() {
		this.searchInput.removeAttribute('data-loading')
		this.searchInput.setAttribute('aria-expanded', 'false')
		this.searchStatus.innerText = ''
		const suggestions = document.getElementById('js-search-suggestions')
		if (suggestions) {
			suggestions.remove()
		}
	}

	/**
	 * Get suggestions via AJAX or from cache
	 */
	getSuggestions() {
		const val = this.searchInput.value.trim()
		if (val.length >= 3) {
			window.clearTimeout(this.autocompleteTimeout)
			this.searchInput.setAttribute('data-loading', 'true')
			this.autocompleteTimeout = window.setTimeout(() => {
				if (Object.prototype.hasOwnProperty.call(this.autocompleteCache, val)) {
					this.displaySuggestions(this.autocompleteCache[val], val)
				} else {
					const xhr = new XMLHttpRequest()
					xhr.addEventListener('load', () => {
						const data = JSON.parse(xhr.responseText)
						this.autocompleteCache[val] = data.results || []
						this.displaySuggestions(this.autocompleteCache[val], val)
					})
					xhr.open('GET', this.searchURL + '?q=' + encodeURIComponent(val))
					xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
					xhr.send()
				}
			}, 500)
		} else {
			this.removeSuggestions()
		}
	}

	/**
	 * Display list of suggestions
	 *
	 * @param {array} results Array of suggestions, where each suggestion is an object with
	 *                        properties desc, title, and url
	 * @param {string} q Query parameter as a string.
	 */
	displaySuggestions(results, q) {

		this.removeSuggestions()
		if (!results.length) return

		// results list
		const listElement = document.createElement('ul')
		listElement.classList.add('suggest-search__list')
		listElement.setAttribute('id', 'js-search-suggestions')
		listElement.setAttribute('role', 'listbox')
		listElement.addEventListener('blur', () => {
			this.removeSuggestions()
		})
		this.suggestionsContainer.appendChild(listElement)

		// set aria-label
		let searchInputLabel = document.querySelector('label[for=' + this.searchInput.id + ']')
		if (!searchInputLabel) {
			const parent = this.searchInput.parentElement
			if (parent.tagName === 'LABEL') {
				searchInputLabel = parent
			}
		}
		if (searchInputLabel) {
			listElement.setAttribute('aria-label', searchInputLabel.innerText)
		}

		// set aria-expanded attribute
		this.searchInput.setAttribute('aria-expanded', 'true')

		// update status
		this.searchStatus.innerText = results.length + ' ' + (this.searchInput.getAttribute('data-results-status') || 'results')

		let itemNum = 0

		results.forEach(result => {

			itemNum++

			// results list item
			const listItemElement = document.createElement('li')
			listItemElement.classList.add('suggest-search__list-item')
			listItemElement.setAttribute('role', 'option')
			listItemElement.setAttribute('aria-setsize', results.length)
			listItemElement.setAttribute('aria-posinset', itemNum)
			listItemElement.setAttribute('aria-selected', 'false')
			listElement.appendChild(listItemElement)

			// results item (link)
			const itemElement = document.createElement('a')
			itemElement.setAttribute('href', result.url)
			itemElement.innerText = result.title
			itemElement.classList.add('suggest-search__item')
			itemElement.addEventListener('blur', () => {
				this.maybeRemoveSuggestions()
			})
			listItemElement.appendChild(itemElement)

			// add event listeners for controlling selected state
			itemElement.addEventListener('focus', () => {
				itemElement.parentElement.setAttribute('aria-selected', 'true')
			})
			itemElement.addEventListener('blur', () => {
				itemElement.parentElement.setAttribute('aria-selected', 'false')
			})

			// add event listener for arrow keys
			itemElement.addEventListener('keydown', (e) => {
				if (this.handleArrowKeys(e)) {
					e.preventDefault()
				}
			})
		})

		if (results.length >= 5) {

			itemNum++

			// more list item
			const moreListItemElement = document.createElement('li')
			moreListItemElement.classList.add('suggest-search__list-item')
			moreListItemElement.classList.add('suggest-search__list-item--more')
			moreListItemElement.setAttribute('role', 'option')
			moreListItemElement.setAttribute('aria-setsize', results.length)
			moreListItemElement.setAttribute('aria-posinset', itemNum)
			moreListItemElement.setAttribute('aria-selected', 'false')
			listElement.appendChild(moreListItemElement)

			// more item (link)
			const moreItemElement = document.createElement('a')
			moreItemElement.setAttribute('href', this.searchURL + '?q=' + encodeURIComponent(q))
			moreItemElement.innerText = this.suggestionsContainer.getAttribute('data-all-label')
			moreItemElement.classList.add('suggest-search__more')
			moreItemElement.addEventListener('blur', () => {
				this.maybeRemoveSuggestions()
			})
			moreListItemElement.appendChild(moreItemElement)

			// add event listeners for controlling selected state
			moreItemElement.addEventListener('focus', () => {
				moreItemElement.parentElement.setAttribute('aria-selected', 'true')
			})
			moreItemElement.addEventListener('blur', () => {
				moreItemElement.parentElement.setAttribute('aria-selected', 'false')
			})

			// add event listener for arrow keys
			moreItemElement.addEventListener('keydown', (e) => {
				if (this.handleArrowKeys(e)) {
					e.preventDefault()
				}
			})
		}
	}

}
